
import request from "@/utils/system/request";

export interface AccessLogSearchDto {
  responseTime: number | null;
  description: string | null;
  url: string | null;
  startTime: string | null;
  endTime: string | null;
}

export default {
  findList(page: number, size: number,data: AccessLogSearchDto) {
    return request({
      url: `admin/AccessLog/FindList/${size}/${page}`,
      method: "post",
      data
    });
  },
  clearLog() {
    return request({
      url: `admin/AccessLog/clearAccessLog`,
      method: "get"
    });
  },
};
