import { computed, defineComponent, onMounted, reactive, toRefs } from "vue";
import service from "@/api/admin/log";
import dayjs from "dayjs";
import tools from "@/utils/tools";
export default defineComponent({
  setup() {
    const state = reactive({
      table: {
        search: {
          responseTime: null,
          description: null,
          url: null,
          startTime: null,
          endTime: null
        },
        page: 1,
        size: 10,
        total: 0,
        data: [],
        loading: false,
        categoryItem: [],
        tagItem: []
      },
      dateValue: null
    });
    const methods = {
      findList() {
        state.table.loading = true;
        service.findList(state.table.page, state.table.size, state.table.search).then(res => {
          console.log(res);
          state.table.data = res.data.data;
          state.table.page = res.data.page;
          state.table.size = res.data.size;
          state.table.total = res.data.total;
        }).finally(() => {
          state.table.loading = false;
        });
      },

      clearLog() {
        state.table.loading = true;
        service.clearLog().then(res => {
          if (res.code === 200) {
            tools.message("清除成功");
            methods.findList();
          }
        }).finally(() => {
          state.table.loading = false;
        });
      },

      handlePageChange() {
        methods.findList();
      },

      onReset() {
        state.dateValue = null;
        Object.keys(state.table.search).forEach(key => {
          state.table.search[key] = null;
        });
        methods.findList();
      },

      goDetails() {},

      timeChangeHandler(date) {
        if (date !== null) {
          state.table.search.startTime = dayjs(date[0]).format("YYYY/MM/DD");
          state.table.search.endTime = dayjs(date[1]).format("YYYY/MM/DD");
        } else {
          state.table.search.startTime = null;
          state.table.search.endTime = null;
        }
      }

    };
    onMounted(() => {
      methods.findList();
    });
    return { ...toRefs(state),
      ...methods
    };
  }

});